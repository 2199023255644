export default {
  assetsRoot: import.meta.env.VITE_ASSETS_URL,
  userAvatarAssetPath: '/uploads/avatars/',
  groupLogoAssetPath: '/uploads/groups/',
  companyLogoAssetPath: '/uploads/companies/',
  productLogoAssetPath: '/uploads/products/',
  clientLogoAssetPath: '/uploads/clients/',
  productMediaAssetPath: '/uploads/product_media/',
  productDocumentAssetPath: '/uploads/product_documents/',
  presentationMediaPath: '/uploads/presentation_media/'
}
