import { merge } from 'lodash-es'

import apiConfig from './api'
import assetsConfig from './assets'
import googleConfig from './google'
import mapboxConfig from './mapbox'
import rollbarConfig from './rollbar'
import trackingConfig from './tracking'

import local from './local'

import production from './environment/production'
import demo from './environment/demo'
import beta from './environment/beta'
import test from './environment/test'
import development from './environment/development'
import qa from './environment/qa'

const environment = {
  production,
  demo,
  beta,
  test,
  development,
  qa
}[import.meta.env.VITE_APP_ENV]

const config = {
  api: apiConfig,
  assets: assetsConfig,
  google: googleConfig,
  mapbox: mapboxConfig,
  rollbar: rollbarConfig,
  tracking: trackingConfig
}

merge(config, environment)
merge(config, local)

export const {
  api,
  assets,
  google,
  mapbox,
  rollbar,
  tracking
} = config

export default config
