import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/template/:code',
      name: 'Template',
      component: () => import('./views/Template.vue')
    },
    {
      path: '/slide/:code',
      name: 'SlideLoader',
      component: () => import('./views/slide/Loader.vue'),
      children: [
        {
          path: 'view',
          name: 'SlideView',
          alias: '',
          component: () => import('./views/slide/View.vue')
        },
        {
          path: 'print',
          name: 'SlidePrint',
          component: () => import('./views/slide/Print.vue')
        }
      ]
    },
    {
      path: '/:code',
      name: 'PresentationLoader',
      component: () => import('./views/presentation/Loader.vue'),
      children: [
        {
          path: 'view',
          name: 'PresentationView',
          alias: '',
          component: () => import('./views/presentation/View.vue')
        },
        {
          path: 'print',
          name: 'PresentationPrint',
          component: () => import('./views/presentation/Print.vue')
        },
        {
          path: 'print-slide/:slideIndex',
          name: 'PresentationPrintSlide',
          component: () => import('./views/presentation/PrintSingleSlide.vue')
        },
        {
          path: 'map/:size',
          name: 'PresentationMapPrint',
          component: () => import('./views/presentation/MapPrint.vue')
        }
      ]
    },
    {
      path: '/unsupported-browser',
      name: 'UnsupportedBrowser',
      component: () => import('@/views/UnsupportedBrowser.vue')
    },
    {
      path: '/layout/:code',
      name: 'LayoutLoader',
      component: () => import('./views/layout/Loader.vue'),
      children: [
        {
          path: 'view',
          name: 'LayoutView',
          alias: '',
          component: () => import('./views/layout/View.vue')
        },
        {
          path: 'print',
          name: 'LayoutPrint',
          component: () => import('./views/layout/Print.vue')
        }
      ]
    }
  ]
})

const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1
router.beforeEach(async (to, from, next) => {
  if (isIE) {
    if (to.name === 'UnsupportedBrowser') return next()
    return next({ name: 'UnsupportedBrowser' })
  }

  return next()
})

export default router
