import Vue from 'vue'
import dayjs from 'dayjs'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import smoothscroll from 'smoothscroll-polyfill'
import config from './config'
import HttpPlugin from '@advisr/frontend-ui-components/src/plugins/HttpPlugin'
import CurrencyFormatter from '@advisr/frontend-ui-components/src/plugins/CurrencyFormatter'
import ErrorTracking from '@advisr/frontend-ui-components/src/plugins/ErrorTracking'
import Vue2TouchEvents from 'vue2-touch-events'

// CSS imports
import '@advisr/frontend-ui-components/src/assets/global.css'
import '@advisr/frontend-ui-components/src/assets/css/presentation-fonts.css'
import './assets/styles/global.css'

smoothscroll.polyfill()

Vue.use(HttpPlugin)

Vue.config.productionTip = false

Vue.filter('number_with_commas', (value) => {
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
})

Vue.use(CurrencyFormatter)
Vue.use(ErrorTracking, config.rollbar)
Vue.use(Vue2TouchEvents)

Vue.filter('short_date', value => {
  try {
    const date = dayjs(value)
    if (!date.isValid()) return null
    return date.format('MM/DD/YYYY')
  } catch (err) {
    return ''
  }
})

// Analytics
const useAnalytics = (
  config.google &&
  config.google.analytics &&
  config.google.analytics.apiKey &&
  config.google.analytics.apiKey !== ''
)

if (useAnalytics) {
  Vue.use(VueGtag, {
    config: { id: config.google.analytics.apiKey },
    pageTrackerTemplate (to) {
      return {
        page_title: `Presentation: ${to.name}`,
        page_path: to.path,
        page_location: window.location.href
      }
    }
  }, router)
}

export const vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
