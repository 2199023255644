export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-69XSB0YQLM'
    }
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoZnEwdzAxb2oyam54YnNzZ2R0dTEifQ.VPvMN8Mx-W73IVoaoThjQw'
  },
  rollbar: {
    accessToken: 'f6f0cb5d42c5441b898b38357d71d93f'
  }
}